import React, { useRef, useEffect } from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const heroRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting || entry.boundingClientRect.top >= 0) {
            entry.target.classList.add('animate');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    const heroElement = heroRef.current;
    const contentElement = contentRef.current;

    if (heroElement) {
      observer.observe(heroElement);
      // Trigger animation immediately if already in view
      if (heroElement.getBoundingClientRect().top >= 0) {
        heroElement.classList.add('animate');
      }
    }

    if (contentElement) {
      observer.observe(contentElement);
      // Trigger animation immediately if already in view
      if (contentElement.getBoundingClientRect().top >= 0) {
        contentElement.classList.add('animate');
      }
    }

    return () => {
      if (heroElement) observer.unobserve(heroElement);
      if (contentElement) observer.unobserve(contentElement);
    };
  }, []);

  return (
    <div className="privacy-policy-page">
      <section className="privacy-hero" ref={heroRef}>
        <h1>ALPHA FUNDER (PTY) LTD. PRIVACY POLICY</h1>
        <h3>Your privacy is important to us.</h3>
      </section>
      <section className="privacy-content" ref={contentRef}>
        <h2>Overview</h2>
        <p>When using our services, we process the personal data of our customers ("you", "your" or the "Customer"). 
        This Privacy Policy (the “Policy”) provides information about how your data is processed. It covers the following areas:</p>
        <ol style={{ paddingLeft: '20px'}}>
            <li>Who controls your personal data and whom to contact?</li>
            <li>Who does this Privacy Policy apply to?</li>
            <li>What information do we collect about you and how do we obtain it?</li>
            <li>How do we collect your Personal Information?</li>
            <li>Lawful Processing of Personal Information</li>
            <li>How do we use your personal data?</li>
            <li>How do we store and secure your personal data?</li>
            <li>How Long Do We Process Your Data?</li>
            <li>Data Sharing and Transfers to Third Countries</li>
            <li>Third Party Links / Websites</li>
            <li>Your rights</li>
            <li>Information Officer and Responsibilities</li>
            <li>Cookies and other information</li>
            <li>Consent for Direct Marketing</li>
            <li>Handling of Special Personal Information</li>
            <li>Detailed Security Measures</li>
            <li>Prior Authorisation for Certain Processing Activities</li>
            <li>Notification of Data Breaches</li>
            <li>General</li>
        </ol>
        <br></br>
        <p>We take our responsibilities regarding the protection of personal information very seriously. 
            This policy explains how we use personal information we may obtain from you and about you. By using this Website, you agree to this policy. 
            By submitting your personal information you agree to the use of that personal information as set out in this privacy policy. 
            If our privacy policy changes, we will post the changes on our Website. Continued use of our Website by you will signify that you agree to such changes.</p>

        <h2>1. Who Controls Your Personal Data and Whom to Contact?</h2>
        <p>The controller of your personal data is Alpha Funder (Pty) Ltd located at 266 Brooks Street, Brooklyn, Pretoria, South Africa, Enterprise Number: 2024 / 221857 / 07 (hereinafter referred to as “we”, “our”, “our Company”, or “Controller”). 
            You can contact Alpha Funder (Pty) Ltd via email at privacy@alphafunder.io for any questions or to exercise your rights related to your personal data.</p>
        <h2>2. Who does this Privacy Policy apply to?</h2>
        <p>This Privacy Policy applies to visitors to our Website.
            This policy does not apply to any third-party websites which may be accessible via links on our Website, and such sites will have their own privacy policies in terms of how your data
            is processed by them. It is your responsibility to review such policies before you submit your data to them.</p>
        <h2>3. What Information Do We Collect About You and How Do We Obtain It?</h2>
        <p>In this section, you can read general information about which of your personal data we collect and how we collect it. Detailed information on the purposes for which we process personal data is given in Section 3 of this Policy. 
            All information on the basis of which we can directly or indirectly identify you or which is related to you is considered personal data. <br></br>
            <br></br>
            When you use the Website, we may collect certain information that does not identify you personally such as your operating system, device type and make, browser type and internet service provider. This type of information that does not identify you personally is not Personal Information. 
            We collect your Personal Information, so that we can provide our products and services to you. We only collect as much data as we need.<br></br>
            <br></br>
            When you access the Website, we may also collect Personal Information that you provide
            to us. For purposes of this Privacy Policy, "Personal Information" shall mean any
            information of a personal nature by which you can be identified as an individual such as
            your name, postal address, email address, telephone number, home country, postal code, date of birth, gender, age, payment and/or financial details. However, we collect the hereunder information
            </p>
            <br></br>
            <h3>Information We Collect:</h3>
            <ul style={{ paddingLeft: "40px"}}>
                <li>
                    <p><strong>Data you provide:</strong> Information you give us directly, such as when you register on our website, fill in details in your user account, order services, participate in events and projects, or communicate with us. This includes:</p>
                    <ol style={{paddingLeft: "20px"}}>
                        <li>Identification and contact data: Name, surname, telephone number, email address, postal address, date of birth, username, password, and business identification number or tax registration number if you are an entrepreneur.</li>
                        <li>Payment data: Bank details and other payment information.</li>
                        <li>Communication records: Records of your communication with us through customer support or social networks.</li>
                    </ol>
                </li>
                <br></br>
                <li>
                    <p><strong>Data we collect automatically:</strong> Information collected automatically when you use our services. This includes:</p>
                    <ol style={{paddingLeft: "20px"}}>
                        <li>Device data: IP address, device type, operating system, browser used, and
                        connection provider.</li>
                        <li>Website usage data: Date, time, and duration of visit, country from which you
                        visit the website, and website browsing history.</li>
                        <li>Service usage data: Login and logout information, account settings, value of
                        your fictitious capital, account currency, and business strategy.</li>
                    </ol>
                </li>
                <br></br>
                <li>
                    <p><strong>Data from third parties:</strong> Information provided by third parties such as trading platform providers and social network operators if you link your accounts. This
                    includes:</p>
                    <ol style={{paddingLeft: "20px"}}>
                        <li>Simulated transaction data: Type of financial instrument, time of opening and
                        closing the position, amount of investment, profit and loss.</li>
                        <li>Social network data: Username, profile picture, and email address associated
                        with the social network account.</li>
                    </ol>
                </li>
                <br></br>
                <p>The supply of your Personal Information is voluntary; however, you acknowledge that we cannot make certain services available to you on the Website if you do not wish to supply such Personal Information.<br></br>
                We may further use companies to monitor website traffic, which may, in some instances, store your information, including the Personal Information you submit to such third parties.</p>
            </ul>
        <h2>4. How do we collect your Personal Information?</h2>
        <p>We collect data in different ways on our Website, such as when you directly give us your Personal Information. This includes but is not limited to instances when</p>
        <ul style={{paddingLeft: "20px"}}>
            <li>you visit the Website;</li>
            <li>you provide certain Personal Information (such as your name, address, telephone
                and e-mail address) by filling in forms or by corresponding with us by e-mail or
                otherwise;</li>
                <li>information collected from website cookies;</li>
                <li>you register for e-mail alerts on the Website;</li>
                <li>you request information to be sent to you; and</li>
                <li>you provide feedback to us.</li>
        </ul>
        <br></br>
        <p>When you interact with our Website, we may automatically collect technical data about your equipment, browsing actions and patterns. Through analytics, we use anonymised aggregated data such as statistical or demographic data.
        In limited circumstances, we may collect Personal Information from third parties or if publicly available, such as from external services providers contracted by us to host or maintain certain sections of this Website.</p>
            
        <h2>5. Lawful Processing of Personal Information</h2>
        <p>We collect, process and store your Personal Information on the following legal bases:</p>
        <ul style={{paddingLeft: "20px"}}>
            <li>it is required to process your Personal Information in connection with the performance of a contract entered into with you;</li>
            <li>to use your Personal Information to comply with our legal obligations;</li>
            <li>to pursue our legitimate interest, provided that we only use your Personal Information to pursue our legitimate interests where your interests and fundamental rights or
            freedoms do not override those interests; and</li>
            <li>for any other purpose that you have agreed and given us your explicit consent, which
            you may withdraw at any time.</li>
        </ul>
        <br></br>
        <p>We will only use your Personal Information for the purposes it was collected, as well as any other reason necessary and related or associated thereto. Please note that we may process your Personal Information without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</p>
        <h2>6. How Do We Use Your Personal Data?</h2>
        <p>We require certain Personal Information in respect of all our customers. There may be instances in which the Personal Information that you provide to us or which we collect constitutes Personal Information of someone other than yourself, such as where you provide a third party's Personal Information to us, you warrant that the information is both true and
            accurate and that you have the necessary consent to share it with us, unless you have another lawful basis for sharing the data with us.
            If you are sharing the Personal Information of a child or consenting to anything on behalf of a child, you warrant that you are a Competent Person (as such term is defined in (POPI) in respect of that child and accordingly that you are a person who is legally competent to consent to any action or decision being made or taken in respect of any matter concerning such child.<br></br><br></br>
            We may use the information, including your Personal Information, for the following purposes:</p>
            <h3>Purposes of Data Use</h3>
            <ul style={{paddingLeft: "20px"}}>
                <li>
                    <p><strong>Registration and user account management:</strong></p>
                    <ol style={{paddingLeft: "40px"}}>
                        <li>To create and manage your account.</li>
                        <li>Personal data: Identification and contact data; user account settings.</li>
                        <li>Legal ground: Necessary for the performance of the contract.</li>
                    </ol>
                </li>
                <li>
                    <p><strong>Service provision and contract performance:</strong></p>
                    <ol style={{paddingLeft: "40px"}}>
                        <li>To provide you with access, tools, support, and to perform trades.</li>
                        <li>Personal data: Identification and contact data; payment data for paid services;
                        service usage data; simulated trade data; social network data if linked.</li>
                        <li>Legal ground: Necessary for the performance of the contract and our
                        legitimate interests.</li>
                    </ol>
                </li>
                <li>
                    <p><strong>Marketing communications:</strong></p>
                    <ol style={{paddingLeft: "40px"}}>
                        <li>To send you information about our services and products or events we participate in.</li>
                        <li>Personal data: Identification and contact data.</li>
                        <li>Legal ground: Our legitimate interest in maintaining customer relationships or
                        based on your consent.</li>
                    </ol>
                </li>
                <li>
                    <p><strong>Customer support and communication:</strong></p>
                    <ol style={{paddingLeft: "40px"}}>
                        <li>To assist you when you contact our support services.</li>
                        <li>Personal data: Identification and contact data; communication records; social
                        network data if used to contact us.</li>
                        <li>Legal ground: Our legitimate interest in providing customer support.</li>
                    </ol>
                </li>
                <li>
                    <p><strong>Improving services:</strong></p>
                    <ol style={{paddingLeft: "40px"}}>
                        <li>To develop, test, and enhance our services and their security.</li>
                        <li>Personal data: Identification and contact data; device data; website usage
                        data; service usage data; simulated trade data.</li>
                        <li>Legal ground: Our legitimate interest in improving our services.</li>
                    </ol>
                </li>
                <li>
                    <p><strong>Analytics:</strong></p>
                    <ol style={{paddingLeft: "40px"}}>
                        <li>To understand how our website and services are used and to estimate
                        customer preferences.</li>
                        <li>Personal data: Identification and contact data; payment data; device data;
                        website usage data; service usage data; simulated trade data.</li>
                        <li>Legal ground: Our legitimate interest in analysing customer behaviour.</li>
                    </ol>
                </li>
            </ul>
            <br></br>
        <h2>7. How Do We Store and Secure Your Personal Data?</h2>
        <p>We recognize that protecting your privacy is important to you. For this reason, we are committed to protecting the Personal Information you provide in a variety of ways.
            We process your personal data in an encrypted database on secure devices. We have implemented organizational and technical measures to protect your data from unauthorized
            access, alteration, disclosure, or destruction. These measures are strictly adhered to, ensuring the security of your data.
            We will do our best to protect your Personal Information, and we will use technology that
            will help us to do this, however, the transmission of information via the internet is not completely secure and as such we cannot guarantee the security of your information when transmitted to our Website. Any transmission of your information on the Website is entirely at your own risk. 
            Once your information is received by the Website, we and/or our third party service providers shall use strict procedures and security features to try and prevent unauthorised access.</p>
        <h2>8. How Long Do We Process Your Data?</h2>
        <p>Personal data is processed to the extent necessary to fulfil the purposes described above and for the time necessary to achieve those purposes or for a period directly stipulated by law. Thereafter, the personal data are deleted or anonymised.
            We will not retain your Personal Information longer than the period for which it was originally required, unless we are required by law to do so, or you consent to us retaining such information for a longer period. Where your information is no longer required, we will
            ensure it is disposed of in a secure manner and, where required by applicable law, we will notify you when such information has been disposed of.
            If you wish to understand more about the retention periods applicable to your personal information or would like to receive more information about our data retention, storage and disposal policy, contact our Information Officer (see paragraph 12 below).</p>
        <br></br>
        <p><strong>Retention Periods:</strong></p>
                <ul style={{paddingLeft: "20px"}}>
                    <li><strong>Contract performance:</strong> Up to 10 years from the termination of the last contract.</li>
                    <li><strong>Marketing communications:</strong> Up to 5 years after the last contract or until you
                    unsubscribe.</li>
                    <li><strong>Service improvement:</strong> Usually up to 6 months.</li>
                    <li><strong>Legal obligations:</strong> As required by relevant legal regulations, such as 10 years for
                    tax purposes.</li>
                    <li><strong>Consent-based processing:</strong> For the duration specified in your consent.</li>
                </ul>
            <br></br>
        <h2>9. Data Sharing and Transfers to Third Countries</h2>
        <h3>Sharing with Third Parties:</h3>
        <p>
        We will not use your Personal Information for any purpose other than as stated in paragraph 3 above without your prior consent. 
        We will not use or disclose your Personal Information to third parties without your consent, unless the use and/or disclosure is necessary to</p>
        <ul style={{paddingLeft: "40px"}}>
            <li>comply with applicable law, an order of court or legal process; and/or</li>
            <li>disclosure is necessary to protect and defend our rights or property.</li>
        </ul>
        <br></br>
        <p>We are entitled to disclose your Personal Information to all our affiliates, agents, employees and/or third-party service providers and/or suppliers who assist us in provision of our products or services and to communicate with you via our Website or any
            telecommunications and/or email correspondence, by virtue of which need to know your Personal Information.
            We may also provide information to third party service providers who process data on our behalf and assist with amongst other things information technology services, customer services, website operations and product or services provision, including but not limited to hosting providers, payment system operators, IT support, marketing agencies, and other necessary third party service providers.
            You understand that we may disclose or share Personal Information with third parties as outlined above to operate the Website and render our products and services to you. If you are concerned about this, you should not use the Website.
            On rare occasions, we may be required to disclose your Personal Information due to legal or regulatory requirements. In such instances, we reserve the right to disclose your Personal Information as required to comply with any legal obligations, including but not limited to complying with court orders and/or warrants.
            We may further disclose data about our users to the relevant law enforcement, in the good faith belief that such disclosure is reasonably necessary to enforce this Privacy Policy, respond to any claims, protect the rights, property, or our personal safety or the personal safety of our employees, clients or the general public.</p>
            <h3>International Transfers</h3><br></br>
            <ul style={{paddingLeft: "40px"}}>
                <li><strong>Third countries and international organisations:</strong> We may, from time to time, transfer, store or host your Personal Information on servers outside of your jurisdiction and store and transfer your data outside of your jurisdiction. Should your Personal Information be transferred outside your jurisdiction, we shall aim to ensure that at least the same level of data protection will be applied in the other country where the data is being processed and/or that we and/or the service provider acts at all times in compliance with applicable privacy laws. We will at all times aim to ensure that appropriate security safeguards are in place to secure and protect such Personal Information and take measures to ensure that the Personal Information handled will receive at least the level of protection as required by all applicable laws.</li>
            </ul>
            <br></br>
            <p>In addition, we may transfer your data to persons in respect of which you give your consent thereto, as well as in the cases where it is our obligation under the law or under a judicial or any other legally binding decision. Each such entity to whom personal data are transferred is contractually obligated to protect your personal data in accordance with legal provisions and to process the data exclusively according to our instructions. Certain third parties collect personal data for their own purposes as controllers. In such a case, their own rules for the processing of personal data shall apply.</p>
        <h2>10. Third Party Links / Websites</h2>
        <p>We may make use of third-party service providers to monitor our customers interests as such our Website may contain links to third-party websites. If you follow and click on any such link to a third-party website, you leave the Website. As such, we are not responsible for the content of these third-party websites nor their security and the manner of use or processing of your Personal Information.
        This Privacy Policy neither intends nor does it govern (to whatsoever extent) the Personal Information provided to, stored on, or used by these third-party websites. We recommend that when entering or making use of a third party's website, you review and familiarise yourself with its privacy policy as it relates to how such third parties use, collect, process, store and protect your Personal Information.</p>

        <h2>11. Your Rights</h2>
        <p>You can exercise all your rights mentioned below, including your right to erasure of personal data and the withdrawal of your consent, by sending a request to us via email at privacy@alphafunder.io. There are no specific words that you must use in your request, but please be specific enough, so we can help you with your request (e.g. identify yourself, specify the right you wish to exercise, what action you wish us to take, details of what personal data you want erased/deleted, etc.).</p>
        <h3>Response Time</h3><br></br>
        <p>We will acknowledge your request within 10 business days and respond fully within 30 days. If we require additional time, we will inform you of the extension and the reasons for it.</p>
        <h3>Your rights include:</h3><br></br>
        <ol style={{paddingLeft: "20px"}}>
            <li><strong>Right of Access</strong>You have the right to request:
                <ul style={{paddingLeft:"20px"}}><li>Confirmation of whether we process your personal data.</li>
                <li>Access to your personal data and details on how it is processed.</li></ul>
            </li>
            <li><strong>Right to Rectification</strong><br></br>If your personal data is inaccurate or incomplete, you can request rectification. You may also update your personal data through your user account settings.</li>
            <li><strong>Right to Erasure ("Right to be Forgotten")</strong><br></br>You can request the deletion of your personal data if:
                <ul style={{paddingLeft:"40px"}}>
                    <li>The data is no longer necessary for the purposes for which it was collected.</li>
                    <li>You withdraw consent (where consent is the legal ground for processing).</li>
                    <li>You object to the processing, and there are no overriding legitimate grounds.</li>
                    <li>The data has been unlawfully processed.</li>
                </ul>
            </li>
            <li><strong>Right to Restriction of Processing</strong><br></br>You can request the restriction of processing your personal data under certain conditions, such as while we verify the accuracy of your data or if you object to processing.</li>
            <li><strong>Right to Data Portability</strong><br></br>You have the right to receive your personal data in a structured, commonly used, and machine-readable format. You may also request that we transfer your data to another data controller where technically feasible.            </li>
            <li><strong>Right to Object</strong><br></br>You can object to the processing of your personal data for:
                <ul style={{paddingLeft:"40px"}}>
                    <li>Direct marketing purposes, in which case we will stop processing your data for this purpose.</li>
                    <li>Processing based on our legitimate interests, unless we have compelling legitimate grounds for the processing.</li>
                </ul>
            </li>
            <li><strong>Right to Withdraw Consent</strong><br></br> You can withdraw your consent at any time. Withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal.</li>
            <li><strong>Right Not to be Subject to Automated Decision-Making</strong><br></br>You have the right not to be subject to decisions based solely on automated processing, including profiling, which produces legal effects concerning you or similarly affects you significantly.</li>
            <li><strong>Right to Lodge a Complaint</strong><br></br>If you believe we are processing your personal data in violation of relevant standards, you have the right to lodge a complaint with the Information Regulator:
                <ul style={{paddingLeft:"40px"}}>
                    <li><strong>Contact Details:</strong>27 Stiemens St, Braamfontein, Johannesburg, 0001, South Africa.</li>
                    <li><strong>Email:</strong>enquiries@inforegulator.org.za</li>
                    <li><strong>Website:</strong>Information Regulator South Africa</li>
                </ul>
            </li>
        </ol>
        <br></br>
        <h2>12. Information Officer and Responsibilities</h2>
        <p>In compliance with POPIA, we have appointed an Information Officer responsible for ensuring compliance with the Act. The Information Officer's duties include:</p>
        <ul style={{paddingLeft:"40px"}}>
            <li>Monitoring compliance with POPIA.</li>
            <li>Conducting impact assessments.</li>
            <li>Ensuring that data processing is conducted in accordance with the Act.</li>
            <li> Handling data subject requests and complaints.</li>
        </ul>
        <br></br>
        <p>Contact details of the Information Officer:</p>
        <ul style={{paddingLeft:"40px"}}>
            <li>Name: Tyne Potgieter</li>
            <li>Email: tyne@alphafunder.io</li>
        </ul>

        <h2>13. Cookies and other information</h2>
        <p>The Website makes use of "cookies". A cookie is piece of data that is stored on your internet browser on your computer. Cookies are used to provide information about your preferences on the Website so that we can provide you with better, more customised content and service in the future when you return to our Website.
        You can set your browser to alert yourself when you are going to be sent a cookie, giving you the opportunity to decide whether to accept it.</p>

        <h2>14. Consent for Direct Marketing</h2>
        <p>We will not send any unsolicited direct electronic communications to you unless you have given us explicit consent to receive such direct electronic communications. You can provide consent during the registration process or through your account settings. You can withdraw your consent at any time by updating your preferences in your account or by contacting us at privacy@alphafunder.io.</p>

        <h2>15. Handling of Special Personal Information</h2>
        <h3>Processing Special Personal Information</h3>
        <p>We recognise the sensitive nature of special personal information and adhere to strict guidelines for processing it. Special personal information includes data on race, health, biometrics, or criminal behaviour. We will only process this information under the following conditions:</p>
        <ul style={{paddingLeft:"40px"}}>
            <li>With explicit consent from the data subject.</li>
            <li>As required by law.</li>
            <li>For the establishment, exercise, or defence of a legal claim.</li>
            <li>For historical, statistical, or research purposes in compliance with legal standards.</li>
        </ul>
        <br></br>
        <h3>Processing Personal Information of Children</h3>
        <p>We will not process personal information of children unless:</p>
        <ul style={{paddingLeft:"40px"}}>
            <li>We have obtained consent from a competent person (such as a parent or guardian).</li>
            <li>It is necessary for the establishment, exercise, or defence of a legal claim.</li>
            <li>It is required to comply with international public law obligations.</li>
            <li>It is for historical, statistical, or research purposes in compliance with legal standards.</li>
        </ul>
        <br></br>

        <h2>16. Detailed Security Measures</h2>
        <p>We are committed to ensuring the security of your personal data. Our security measures include:</p>
        <ul style={{paddingLeft:"40px"}}>
            <li><strong>Encryption:</strong> Where possible, personal data is stored in an encrypted format to protect it from unauthorized access.</li>
            <li><strong>Access Controls:</strong> Access to personal data is restricted to authorized personnel only, based on the principle of least privilege.</li>
            <li><strong>Data Anonymisation:</strong> Where possible, personal data is anonymised to ensure privacy protection.</li>
        </ul>
        <br></br>

        <h2>17. Prior Authorisation for Certain Processing Activities</h2>
        <p>We seek prior authorisation from the relevant regulators for processing activities involving:</p>
        <ul style={{paddingLeft:"40px"}}>
            <li>Unique identifiers for purposes other than originally intended.</li>
            <li>Information on criminal behaviour or unlawful conduct on behalf of third parties.</li>
            <li>Personal information for credit reporting purposes.</li>
            <li>Transfer of special personal information or information of children to foreign countries
            that do not provide adequate protection.</li>
        </ul>
        <br></br>
        <h2>18. Notification of Data Breaches</h2>
        <p>In the event of a data breach, we will:</p>
        <ul style={{paddingLeft:"40px"}}>
            <li>Notify the Information Regulator within 72 hours of becoming aware of the breach.</li>
            <li>Inform affected data subjects without undue delay if the breach is likely to result in
            high risk to their rights and freedoms.</li>
            <li>Provide information on the nature of the breach, likely consequences, and measures
            taken to address the breach and mitigate its effects.</li>
        </ul>
        <br></br>
        <h2>19. General</h2>
        <p>You warrant that all information that you provide to us is accurate, truthful and current, and not intended to misrepresent or impersonate any person or entity or falsely or otherwise misrepresent your affiliation with anyone or anything. Failure to adhere to this requirement will entitle us to, among other things, terminate your use of the Website.
            You further acknowledge that we may verify your identity and your Personal Information.
            It is not our intention that any provision of this Privacy Policy be in contravention of any provision of data protection legislation which may apply in your jurisdiction. Therefore, all provisions of this Privacy Policy must be treated as being qualified to ensure that the provisions of the other applicable data protection legislation are complied with.</p>

        <h2> Final Provisions</h2>
        <p>
We may update this policy when necessary and will notify you of significant changes. The latest version of this policy is always available in your customer Section.</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
